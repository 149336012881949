<template>
  <div class="video-info">

    <FormInfo v-if="pageNum == 1" @onSubmit='onSubmit'></FormInfo>
    <FaceRecognition v-else-if="pageNum == 2"  @faceInfo="faceInfo"></FaceRecognition>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
import { realname, signature, getCurUserInfo } from '@/util/api'
import FaceRecognition from './components/faceRecognition/index.vue'
import FormInfo from './components/formInfo/index.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    FormInfo,
    FaceRecognition,
  },
  data(){
    return{
      pageNum: 1, // 1: 表单输入  2:人脸识别
      formData: null,
      videoInfo: null,
      ttobj: null
    }
  },

  mounted(){
    if(this.$route.query.ttobj){

      this.ttobj = JSON.parse(Base64.decode(this.$route.query.ttobj))
      console.log('拿到的参数', this.ttobj)
    }
  },
  methods:{
    ...mapMutations(['setUserInfo']),
    faceInfo(val){
      this.videoInfo = val
      // this.pageNum = 1
      this.reslutM()
      console.log('人脸识别', val)

    },
    onSubmit(val){
      console.log('表单带出数据',val)
      this.formData = val
      this.pageNum = 2
      // this.reslutM()
    },
    reslutM(){
      let obj = {
        ...this.formData,
        image: this.videoInfo.faceliveness.best_image.pic,
        realnameWay: 1,
        idPhoto: this.formData.image,
        realNamePurpose: 1
      }
      console.log("带参", obj)
      realname(obj).then((res)=>{
        console.log('认证之后',res)
        getCurUserInfo().then((res) => {
          this.setUserInfo(res)
          if(this.formData.photoType == 1){ // 身份证
            this.cardSub()
          }else{
            this.goSuccess()
          }
        })
        
      })
    },
    cardSub(){
      if(this.ttobj.newPage){ //从中间页跳转
        // this.goSuccess()
        this.$router.replace({path: '/usePage',query:{}})
        return
      }
      let obj = {
        actionType: 1,
        processSigns: this.ttobj.processSigns,
        allSignData: this.ttobj.allSignData,
        signatureId: this.ttobj.signatureId,
        // authCode:this.inputValue
      }
      signature(obj).then((res) => {
          console.log(res);
          // this.goSuccess()
          this.$router.push({ path: '/success' })
        }).catch(() => {
          this.$router.back(1)
        })
      /**
      getCurUserInfo().then((res) => {
        this.setUserInfo(res)
        signature(obj).then((res) => {
          console.log(res);
          // this.goSuccess()
          this.$router.push({ path: '/success' })
        }).catch(() => {
          this.$router.back(1)
        })
      })
      **/

    },
    goSuccess(){
      this.$toast.success('认证已提交审核，请耐心等待。')
      setTimeout(() => {
        // getSignatureToSignList().then(res =>{
        //   if(res){
        //     this.$store.commit('setSignList',res)
        //   }
        // })
        this.$router.replace({path: '/usePage',query:{}})
        // this.$router.push({ path: '/home' })
      }, 1000);
    }
  }
}
</script>
<style lang="less" scoped>
  .video-info{
    width: 100%;
    height: 100%;
  }
</style>