<template>
  <div class="form-info">
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        name="photoType"
        :value="formData.photoType"
        label="证件类型"
        placeholder="证件类型"
        defaultIndex='1'
        @click="showSelect('photoType')"
        right-icon="arrow-down"
        required
      />
      <van-field required name="imageShow" label="上传证件" class="upload-box">
        <template #input>
          <div class="upload-item2">
            <!-- <van-uploader v-model="fileList" :before-read="beforeRead" :max-count="1" :after-read="readafter($event,'fileList','image')" v-if="fileList.length == 0"> -->
            <van-uploader :before-read="beforeRead" :max-count="1" :after-read="readafter" >
                <img v-if="formData.imageShow" :src="formData.imageShow"  />
                <img v-else src="@/assets/upload-icon.png" />
            </van-uploader>
          </div>
          <div class="span-text">点击拍摄/上传身份证正面照片</div>
        </template>

      </van-field>
      <van-field
        v-model="formData.name"
        name="name"
        label="姓名"
        placeholder="姓名"
        :rules="formRules.name"
        required
      />
      <van-field
        v-if="formData.photoType == '身份证'"
        type="idcard"
        v-model="formData.idcard"
        name="idcard"
        label="证件号"
        placeholder="证件号"
        :rules="formRules.idcard"
        required
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">实名认证</van-button>
      </div>
    </van-form>

    <van-popup v-model="pickObj.showPicker" position="bottom">
      <van-picker
        :title="pickObj.title"
        show-toolbar
        :columns="pickObj.columns"
        @confirm="onPickConfirm"
        @cancel="onPickCancel"
      />
    </van-popup>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { oss } from '@/util/tool'
import { ossPreviewUrl, getCredentialsInfo, getEnumListByCls } from '@/util/api'
export default {
  components: {
  },
  data(){
    return{
      fileList: [],
      list: [
        // {code: 1, name: '身份证'},
        // {code: 2, name: '港澳通行证'},
        // {code: 3, name: '台胞证'},
        // {code: 4, name: '其他证件'},
      ],
      pickObj:{
        title: '请选择',
        showPicker: false,
        valueKey: 'name',
        key: '',
        columns: [],
      },
      formData:{
        photoType: '身份证',
        name: '',
        idcard: '',
        imageShow: '',
        image: '',
      },
      formRules:{
        image: [{ required: true, message: '请上传证件' }],
        photoType: [{ required: true, message: '请选择证件类型' }],
        name: [{ required: true, message: '请输入姓名' }],
        idcard: [{ required: true, message: '请输入证件号' }],
      }
    }
  },
  computed:{
    ...mapState(['userInfo',]),
  },
  created(){
    this.getMJList('EsConPhotoTypeEnum')
    this.formData.name = this.userInfo.name
  },
  methods:{
    getMJList(name){
      getEnumListByCls({enumClassName: name}).then((res)=>{
        this.list = res
        this.formData.formData = this.list[0].name || '身份证'
      })
    },
    beforeRead(file) {
      const isImgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt15M = file.size / 1024 / 1024 < 15;
      if (!isImgType) {
        this.$toast("上传图片只能是 JPG或PNG 格式!");
        return false;
      }
      if (!isLt15M) {
        this.$toast("上传图片大小不能超过 15MB!");
        return false;
      }
      return true;
    },
    readafter(file) {
      console.log(file, "1224");
      if(file){
        this.oss(file?.file,);
      }

    },
    async oss(val,) {
      console.log(val, "124");
      let file = {};
      let obj = {};
      let fileType = "";
      await oss(val).then(({ size, object, host, mimeType }) => {
        console.log(mimeType, "mimeType");
        if (mimeType === "image/jpeg") {
          fileType = ".jpg";
        } else {
          fileType = ".png";
        }
        console.log(object, "object");
        file = {
          fileDisplayName: "signImage",
          fileExt: fileType,
          fileSize: size,
          fileUrl: `${host}${object}`,
        };
        let path = `${host}${object}`;

        this.ossPreviewUrl(path);
        obj.fileInfo = file;
        // obj.fileType = type;
      });
    },
    ossPreviewUrl(path) {

      ossPreviewUrl({ path }).then((res) => {
        this.formData.imageShow = res
        this.formData.image = path
        console.log('表单数据',this.formData)
        if(this.formData.photoType == '身份证'){
          this.getCardInfo(path)
        }
      });
    },
    getCardInfo(path){
      let obj = {
        fileType: 1,
        osspath: path
      }
      getCredentialsInfo(obj).then((res)=>{
        if (res.personName) {
          this.formData.name = res.personName
        }
        if (res.personNo) {
          this.formData.idcard = res.personNo
        }
      })
    },
    showSelect(key) {
      let arr = []
      this.list.map((item)=>{
        arr.push(item.name)
      })
      this.pickObj.columns = arr
      this.pickObj.key = key;
      // this.pickObj.showPicker = true;
      
      setTimeout(() => {
        this.pickObj.showPicker = true;
      }, 200);
    },
    onPickConfirm(value) {
      console.log(value);
      this.formData[this.pickObj.key] = value;
      this.pickObj.showPicker = false;
    },
    onPickCancel() {
      // this.formData[key] = value;
      this.pickObj.showPicker = false;
    },
    onSubmit(values) {
      if(!this.formData.image){
        this.$toast("请上传证件");
        return
      }
      let obj = this.list.find((v)=> v.name == values.photoType)
      let objData = Object.assign({},this.formData)
      objData.photoType = obj.code
      console.log("submit", objData);
      this.$emit('onSubmit', objData)
    },
  }
}
</script>
<style lang="less" scoped>
.video-info {
  width: 100%;
  height: 100%;
  .van-field__control van-field__control--custom{
    display: block;
  }
  .upload-box{
    width: 100%;
    height: 150px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    font-size: 12px;
    /deep/.van-field__control--custom{
      justify-content: space-between;
      margin-top: 10px;
    }
    .span-text {
      // width: 100%;
      height: 10px;
      line-height: 15px;
      text-align: left;
      font-size: 14px;
      color: #333;
      margin: 10px 0;
    }
  }
  .upload-item2 {
    width: 106px;
    height: 106px;
    // background-image: url("../../../../assets/upload-icon.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 1px;

    img {
      width: 100px;
      height: 100px;
    }

    span {
      width: 100%;
      height: 10px;
      line-height: 15px;
      text-align: center;
      font-size: 14px;
      color: #333;
      margin-top: 20px;
    }

    .van-uploader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      /deep/.van-uploader__wrapper {
        width: 100%;
        height: 100%;

        .van-uploader__input-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }

        .van-uploader__upload {
          width: 100%;
          height: 100%;
          margin: 0;
        }

        .van-uploader__preview {
          width: 100%;
          height: 100%;
          margin: 0;

          .van-image {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>